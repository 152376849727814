<template>
  <div>
    <!-- Start Header Area -->
    <Header>
      <img slot="logo" src="@/assets/images/logo/logo.png" />
    </Header>
    <!-- End Header Area -->
    <!-- Start Column Area  -->
    <div  class="rn-column-area rn-section-gap bg_color--5 mt--150">
          <v-row>
            <v-col lg="6"
                    md="6"
                    sm="12"
                    cols="12">
                  <v-row align="center" justify="center" >
                    <v-col align="center" justify="center" >
                      <v-img data-aos="fade-left" data-aos-duration="1200" width="650" src="@/assets/images/blog/mygifts2.png" alt="Creative Agency" />
                    </v-col>
                  </v-row>
                  <v-row align="center" justify="center" >
                    <p>Κατέβασε το app</p><br>
                  </v-row>
                  <v-row align="center" justify="center" >
                    <v-col align="right">
                      <a href="https://apps.apple.com/us/app/id1489983713"  target="blank" class="app-btn1"><v-img width="100" src="@/assets/images/blog/appstore-tra-grey.png" alt=""/></a>
                    </v-col>
                    
                    <v-col>
                      <a href="https://play.google.com/store/apps/details?id=com.rcflavorproba"  target="blank" class="app-btn2"><v-img width="100" src="@/assets/images/blog/googleplay-tra-grey.png" alt="" /></a>
                    </v-col>
                  </v-row>
              </v-col>
              
               <v-col lg="5"
                     md="5"
                     sm="12"
                     cols="12">

                <div class="mb--50 mt--50 container">
                  
                    <div v-if="userGifts.user_gifts.length == 0">
                      Δεν υπάρχουν δεδομένα
                    </div>
                  <div v-else>
                          <div id="userGiftsList" v-for="(userGift, i) in userGifts.user_gifts"
                              :key="i">
                              <v-row class="mb--30 mt--30">
                                  <v-col lg="4" md="4"  sm="12" cols="12" >
                                    <img :src="`${userGift.gift.image}`" width="150px" /> 
                                  </v-col>
                                  <v-col lg="1" md="1"  sm="12" cols="12" >
                                  </v-col>
                                  <v-col lg="7" md="7"  sm="12" cols="12" >
                                     <p><b> {{ userGift.gift.title }} </b></p>
                                     <div v-if="userGift.promo_type.promo_type_id === 9">
                                      <p style="color: #008000" v-if="userGift.is_used">Εξαργυρωμένο</p>
                                     </div>
                                      <p> {{ userGift.gift.description }} </p>
                                      <div v-if="userGift.promo_type.promo_type_id === 9 && userGift.status !== 'Expired'">
                                        <p v-if="!userGift.is_used"><b>Κωδικός εξαργύρωσης: {{ userGift.voucher }}</b></p>
                                      </div>
                                      
                                      <div v-else-if="userGift.status === 'Expired'">
                                        <p style="color: #FF0033">Εληξε</p>
                                      </div>
                                </v-col>
                               </v-row>
                               <hr>
                          </div>
                      
                    </div>
                  </div>
              </v-col>
                           
          </v-row>
          <b-pagination
            class="mt--100"
            v-if="this.userGifts.pagination.total_pages > 1"
            v-model="currentPage"
            :per-page="this.userGifts.pagination.items_per_page"
            :total-rows="this.userGifts.pagination.total"
            aria-controls="userGiftsList"
            v-on:click.native="scrollToTop"
            align="center"
          ></b-pagination> 
    </div>
    <!-- End Column Area  -->

    <Footer />
  </div>
</template>
<script>
  import Header from "@/components/header/Header";
  import Footer from "@/components/footer/FooterTwo";
  import axios from 'axios';
  import { mapMutations, mapActions, mapGetters } from "vuex";

  export default {
    components: {
      Header,
      Footer,
    },
    data() {
      return {
        currentPage: 1,
        loading: true,
      }
    },
    watch: {
      currentPage: async function (newQuestion, oldQuestion) {
        await this.getUserGifts(newQuestion);
      }
    },
    computed: {
       ...mapGetters (["userGifts"]),
       ...mapGetters (["user"]),
      permission() {
        return this.$store.state.user;
      },
      rows() {
        return this.userGifts.length
      }

    },
    async  created () {
      if(!this.user){
        this.$loading(true);
        await this.getUser();
        this.$loading(false);
        if(!this.user){
         this.$router.push('/')
         return
        }
      }
      if(!this.userGifts){
          this.$loading(true);
          await this.getUserGifts(1);
          this.$loading(false);
        }else{
          await this.getUserGifts(1);
        }
    },
    methods: {
      scrollToTop() {
        this.$vuetify.goTo(0);
      },
      ...mapActions(["getUserGifts"]),
      ...mapActions(["getUser"]),
    },
  };
</script>

<style scoped>
.table {
    overflow-y: auto;
}
.table::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(83, 81, 81, 0.3);
    background-color: #004a91;
    border-radius: 1000px;
}

.table::-webkit-scrollbar {
    width: 5px;
    background-color: #004a91;
}

.table::-webkit-scrollbar-thumb {
    background-color: #c4c4c4;
    border-radius: 10px;
}
</style>